:root {
  // CORES
  --color-surface: #1b1b1b;
  --color-surface-variant: #474747;
  --color-outline: #1b1b1b;
  --color-outline-light: #777777;
  --color-primary: #79aa00;
  --color-primary-light: #d7e6b3;
  --color-error: #ba1a1a;
  --material-theme-surfaces-light-surface-3: #fcfcfc;

  // ESPAÇAMENTO
  --spacing-stack-quarck: 4px;
  --spacing-stack-nano: 8px;
  --spacing-stack-xxxxs: 12px;
  --spacing-stack-xxxs: 16px;
  --spacing-stack-xxs: 24px;
  --spacing-stack-xs: 32px;
  --spacing-stack-sm: 40px;
  --spacing-stack-md: 48px;
  --spacing-stack-lg: 56px;
  --spacing-stack-xl: 64px;
  --spacing-stack-xxl: 80px;
  --spacing-stack-xxxl: 128px;
  --spacing-stack-huge: 160px;
  --spacing-stack-giant: 200px;
}
