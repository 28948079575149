$primary: #79aa00;

@use "@angular/material" as mat;

@include mat.core();

$appito-jr-primary-palette: (
  50: #f2f7e4,
  100: #ddeabd,
  200: #c6db92,
  300: #aecd65,
  400: #9cc340,
  500: #7aaa00,
  600: #7aaa00,
  700: #649700,
  800: #4f8300,
  900: #266300,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
  ),
);

$appito-jr-primary: mat.define-palette($appito-jr-primary-palette);
$appito-jr-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$appito-jr-warn: mat.define-palette(mat.$red-palette);

$appito-jr-theme: mat.define-light-theme(
  (
    color: (
      primary: $appito-jr-primary,
      accent: $appito-jr-accent,
      warn: $appito-jr-warn,
    ),
  )
);

@include mat.all-component-themes($appito-jr-theme);

@import "bootstrap/scss/bootstrap.scss";

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4;
  color: var(--color-outline-light);
}

/*CARD*/
.card {
  background: linear-gradient(
      0deg,
      rgba(94, 94, 94, 0.11),
      rgba(94, 94, 94, 0.11)
    ),
    #fcfcfc;
  border-radius: 16px;
  padding: 12px 24px;
  border-radius: 16px;
  border: 0 none;
}

.list {
  padding: 0 12px 12px;
  &__item {
    padding: 16px 0;
    border-bottom: 1px solid #c2c8b4;
  }
}

.icon-round {
  padding: 6px;
  width: 44px;
  height: 44px;
  margin: 0 auto;
  border-radius: 22px;
  background: #e2e2e2;

  .material-symbols-outlined {
    font-size: 32px;
  }
}

.btn {
  border-radius: 100px;
  padding: 9px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.btn-link {
  padding: 9px 12px;
  text-decoration: none;

  &:hover {
    background: rgba(121, 170, 0, 0.08);
    color: var(--color-primary) !important;
  }

  &:active {
    background: rgba(121, 170, 0, 0.12) !important;
    color: var(--color-primary) !important;
  }
}

.btn-primary {
  &,
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: #fff !important;
  }

  &:hover {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    background: var(--color-primary) !important;
  }

  &:active {
    background: var(--color-primary) !important;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background: rgba(31, 31, 31, 0.12);
  border-color: #e5e5e5;
  color: rgba(27, 27, 27, 0.38) !important;

  opacity: 1 !important;
}

.control-full-width {
  position: relative;
  width: 100%;
}

body {
  .mdc-text-field {
    border-radius: 16px;
    font-size: 14px;
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: #f0f0f0;
    .mdc-floating-label {
      color: var(--color-outline-light);
    }
    .mdc-text-field__input {
      color: var(--color-outline-light);
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fcfcfc;
  }
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    display: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--color-error);
  }
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mat-mdc-form-field-error {
    color: var(--color-error);
  }
  .mat-mdc-form-field.ng-invalid.ng-touched
    .mat-mdc-form-field-subscript-wrapper {
    display: block;
  }

  .mat-mdc-form-field-icon-suffix,
  [dir="rtl"] .mat-mdc-form-field-icon-prefix {
    display: none;
    color: var(--color-error);
    padding-right: 12px;
  }

  .mat-mdc-form-field.ng-invalid.ng-touched .mat-mdc-form-field-icon-suffix,
  [dir="rtl"]
    .mat-mdc-form-field.ng-invalid.ng-touched
    .mat-mdc-form-field-icon-prefix {
    display: block;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    white-space: nowrap;
  }

  .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(-8px) translateX(-15px);
  }
}

.form-row {
  margin-bottom: 12px;
}

.form-buttons {
  padding: 12px;
}

.input-group {
  align-items: flex-start;
  mat-form-field:first-child {
    margin-right: 1px;
    .mat-mdc-text-field-wrapper {
      border-right: 1px solid #c2c8b4;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  mat-form-field:last-child {
    .mat-mdc-text-field-wrapper {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

re-captcha > div {
  margin: 0 auto;
}
